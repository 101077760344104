import '../styles/styles.scss';

import React from 'react';
import Layout from '../components/layout';
import NavHeader from '../components/nav';
import TopNav from '../components/topnav';
import Footer from '../components/footer';
import { Link } from 'gatsby';

const FourOhFour = () => {
  return (
    <Layout>
      <TopNav />
      <NavHeader />
      <div className="container">
        <div className="row">
          <div className="col-12 content-wrapper">
            <h1>Pagina niet gevonden</h1>
            <p>Er is geen pagina gevonden met deze url.</p>
            <p>
              Je kunt naar de <Link to="/">homepage</Link> gaan, of de informatie die je zoekt
              vinden via het menu hierboven.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default FourOhFour;
